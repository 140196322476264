import GuestLayout from "@/Layouts/GuestLayout";
import { Head, Link, router, usePage } from "@inertiajs/react";
// hookform
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormProvider from "@/Components/hook-form/FormProvider";
import { RHFCheckbox, RHFTextField } from "@/Components/hook-form";
import {
    Button,
    IconButton,
    InputAdornment,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ApplicationLogo from "@/Components/Images/ApplicationLogo";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .matches(emailRegex, "Invalid email address")
        .required("Email is required"),
    password: Yup.string()
        .required("Password is required.")
        .min(8, "The password field must be at least 8 characters.")
        .max(20, "The password field must not exceed 20 characters."),
});

export default function Login() {
    const { errors } = usePage().props;
    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues: {
            email: "",
            password: "",
            remember: false,
        },
    });
    const { enqueueSnackbar } = useSnackbar();
    const [showPassword, setShowPassword] = useState(false);

    const {
        handleSubmit,
        formState: { isLoading, isSubmitting },
    } = methods;

    useEffect(() => {
        const errorArr = Object.values(errors);
        if (errorArr.length > 0) {
            errorArr.forEach((err) => {
                enqueueSnackbar(err, {
                    variant: "error",
                    autoHideDuration: 2600,
                    preventDuplicate: false,
                });
            });
        }
    }, [errors]);

    const onSubmit = (data) => {
        router.post(route("user.login"), data, {
            preserveScroll: true,
        });
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    return (
        <GuestLayout>
            <Head title="Log in" />
            <Link
                href="/"
                underline="none"
                style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Typography component="span">
                    <ApplicationLogo
                        direction="row"
                        display="flex"
                        justifyContent="center"
                        sx={{ marginTop: 12 }}
                    />
                </Typography>
            </Link>
            <Paper sx={{ width: 1, padding: 3, mt: 2 }} elevation={4}>
                <FormProvider
                    methods={methods}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack sx={{ my: 3 }} textAlign="center">
                        <Typography variant="h4" color="primary">
                            Login to Your Account
                        </Typography>
                        <Typography variant="body2">
                            Enter your Email &amp; password to login
                        </Typography>
                    </Stack>
                    <Stack spacing={2}>
                        <RHFTextField name="email" label="Email Address" />
                        <RHFTextField
                            name="password"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Stack>
                            <Link href={route("password.request")}>
                                <Typography
                                    variant="body2"
                                    textAlign="left"
                                    sx={{ paddingBottom: "15px" }}
                                >
                                    Forgot your password?
                                </Typography>
                            </Link>
                        </Stack>
                        {/* <RHFCheckbox name="remember" label="Remember me" /> */}
                        <Button
                            size="large"
                            disabled={isLoading || isSubmitting}
                            type="submit"
                            variant="contained"
                        >
                            {isLoading || isSubmitting
                                ? "Please wait..."
                                : "Login"}
                        </Button>
                    </Stack>
                    <Typography
                        variant="body2"
                        sx={{ mt: 1 }}
                        textAlign="center"
                    >
                        Don't have account?{" "}
                        <Link href="/register">Create an account</Link>
                    </Typography>
                </FormProvider>
            </Paper>
            <Stack textAlign="center" mt={1}>
                <Typography variant="body1">
                    Designed by{" "}
                    <a
                        target="_blank"
                        rel="nofollow noopener"
                        href="https://infinityhub.com/"
                    >
                        InfinityHub
                    </a>
                </Typography>
            </Stack>
        </GuestLayout>
    );
}
